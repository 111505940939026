/* reset */
button {
    all: unset;
}

.dressing-detailed-info_collapsible-root {
    width: 100%;
}

.dressing-detailed-info_open-close-button__wrapper {
    display: flex;
    justify-content: center;
}

.open-close__button {
    font-family: inherit;
    border-radius: 10px;
    height: 20px;
    width: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: white;
    opacity: 60%;
}
.open-close__button[data-state='closed'] {
    background-color: white;
}
.open-close__button[data-state='open'] {
    background-color: var(--violet-button-hover);
}

.dressing-detailed-info_open-collapsible-prices__outer-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.dressing-detailed-info_open-collapsible-prices__inner-wrapper {
    background: rgb(255,255,255, 0.3);
    width: 60%;
    border-radius: 20px;
    padding: 15px 20px;
}

@media (max-width: 950px) {
    .dressing-detailed-info_open-collapsible-prices__inner-wrapper {
        width: 100%;
        padding: 5px 10px;
    }
}

.dressing-detailed-info_open-collapsible-tags__wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.dressing-detailed-info__collapsible {
    display: flex;
    flex-flow: column;
    justify-content: center;
}
