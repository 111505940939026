.see-more__main-wrapper {
    color: var(--violet-button-hover);
    background: white;
    padding: 5px 8px;
    display: inline-block;
    font-size: 12px;
    border-radius: 10px;
}

.see-more__main-wrapper:hover {
    cursor: pointer;
    color: var(--violet-midde);
    background: var(--violet-see-more-hover);
}
