.yes-no-checkbox_main-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    opacity: 90%;
    font-size: 12px;
}

button {
    all: unset;
}

.yes-no-checkbox-root-left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 35px;
    border: 1px solid white;
    border-radius: 15px 0 0 15px;
}

.yes-no-checkbox-root-left:hover {
    cursor: pointer;
    width: 150px;
    height: 35px;
    border-radius: 15px 0 0 15px;
    opacity: 60%;
}

.yes-no-checkbox-indicator-left {
    display: flex;
    width: 150px;
    height: 35px;
    border-radius: 15px 0 0 15px;
}

.yes-no-checkbox-label-left {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: var(--violet-text);
    width: 151px;
    height: 35px;
    margin-left: -1px;
    border-radius: 13px 0 0 13px;
}

.yes-no-checkbox-label-when-not-checked {
    color: white;
}

.yes-no-checkbox-root-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 151px;
    height: 35px;
    margin-left: -1px;
    border: 1px solid white;
    border-radius: 0 15px 15px 0;
}

.yes-no-checkbox-root-right:hover {
    cursor: pointer;
    width: 151px;
    height: 35px;
    margin-left: -1px;
    border-radius: 0 15px 15px 0;
    opacity: 60%;
}

.yes-no-checkbox-indicator-right {
    display: flex;
    width: 150px;
    height: 35px;
    border-radius: 0 15px 15px 0;
}

.yes-no-checkbox-label-right {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: var(--violet-text);
    width: 152px;
    height: 35px;
    margin-left: -1px;
    margin-right: -1px;
    border-radius: 0 13px 13px 0;
}

@media (min-width: 0px) and (max-width: 250px) {
    .yes-no-checkbox-root-left,
    .yes-no-checkbox-root-left:hover,
    .yes-no-checkbox-label-left,
    .yes-no-checkbox-root-right,
    .yes-no-checkbox-root-right:hover {
        width: 80px;
    }

    .yes-no-checkbox-label-left,
    .yes-no-checkbox-label-right {
        width: 82px;
    }
}

@media (min-width: 251px) and (max-width: 305px) {
    .yes-no-checkbox-root-left,
    .yes-no-checkbox-root-left:hover,
    .yes-no-checkbox-label-left,
    .yes-no-checkbox-root-right,
    .yes-no-checkbox-root-right:hover {
        width: 100px;
    }

    .yes-no-checkbox-label-left,
    .yes-no-checkbox-label-right {
        width: 102px;
    }
}

@media (min-width: 306px) and (max-width: 360px) {
    .yes-no-checkbox-root-left,
    .yes-no-checkbox-root-left:hover,
    .yes-no-checkbox-label-left,
    .yes-no-checkbox-root-right,
    .yes-no-checkbox-root-right:hover {
        width: 120px;
    }

    .yes-no-checkbox-label-left,
    .yes-no-checkbox-label-right {
        width: 122px;
    }
}

@media (min-width: 361px) and (max-width: 500px) {
    .yes-no-checkbox-root-left,
    .yes-no-checkbox-root-left:hover,
    .yes-no-checkbox-label-left,
    .yes-no-checkbox-root-right,
    .yes-no-checkbox-root-right:hover {
        width: 140px;
    }

    .yes-no-checkbox-label-left,
    .yes-no-checkbox-label-right {
        width: 142px;
    }
}
