.results-all-table_main-wrapper {
    width: 100%;
}

.results-all-table_content__inner-wrapper {
    display: flex;
    flex-flow: column;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--violet-switch-button);
}

@media (max-width: 950px) {
    .results-all-table_content__inner-wrapper {
        border-bottom: 1px solid white;
    }
}

.results-all-table_left-right__wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row;
}

.results-all-table_left__wrapper {
    width: 82%;
    order: 1;
    display: flex;
    flex-flow: row;
}

.results-all-table_right__wrapper {
    width: 18%;
    order: 2;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media (max-width: 950px) {
    .results-all-table_left-right__wrapper {
        flex-flow: column;
    }

    .results-all-table_left__wrapper {
        width: 100%;
        flex-flow: column;
        order: 2;
    }

    .results-all-table_right__wrapper {
        width: 100%;
        order: 1;
    }
}

.results-all-table_collapsible__wrapper {
    padding-top: 10px;
}

.results-all-table_content__wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
}

.results-all-table_content-name__header-wrapper {
    width: 20%;
    display: flex;
}

.results-all-table_content-name__wrapper {
    width: 20%;
}

.results-all-table_dressing-name__text {
    font-weight: bold;
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.results-all-table_header__text {
    font-weight: bold;
    font-size: 12px;
    padding: 4px 0 0 0;
    margin: 0;
}

.results-all-table_triangles__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 25px;
    margin-left: 5px;
}

.results-all-table_triangle__wrapper {
    background: rgb(255, 255, 255, 0.3);
    border-radius: 50%;
    margin: 2px 2px 2px 4px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.results-all-table_triangle__wrapper:hover {
    background: white;
    color: var(--violet-midde);
    cursor: pointer;
}

.results-all-table_content-action__wrapper {
    width: 57%;
    padding-right: 20px;
}

.results-all-table_dressing-description__text {
    margin: 0;
    padding: 0;
    line-height: 16px;
    font-size: 13px;
    text-align: left;
}

.results-all-table_content-active-substances__wrapper {
    width: 23%;
    padding-right: 20px;
}

@media (max-width: 950px) {
    .results-all-table_content-name__header-wrapper,
    .results-all-table_content-name__wrapper {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .results-all-table_content-action__wrapper {
        width: 100%;
        margin-bottom: 10px;
    }

    .results-all-table_content-active-substances__wrapper {
        width: 100%;
        display: flex;
        flex-flow: column;
    }
}

.results-all-table_content_more__wrapper {
    width: 60%
}

.results-all-table_content_checkbox__wrapper {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.results-all-table_border-bottom {
    padding-bottom: 15px;
    border-bottom: 1px solid white;
}

@media (max-width: 950px) {
    .display-on-big-screen {
        display: none;
    }
}

@media (max-width: 950px) {
    .results-all-table_content_more__wrapper {
        width: 80%;
    }
    .results-all-table_content_checkbox__wrapper {
        width: 20%;
        justify-content: flex-end;
        padding-right: 10px;
    }
}

.results-all-table_compare-dressings__button {
    display: none;
}

@media (max-width: 950px) {
    .results-all-table_white-line {
        padding-bottom: 20px;
        border-bottom: 1px solid white;
    }

    .results-all-table_compare-dressings__button {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: -webkit-sticky; /* Safari & IE */
        position: sticky;
        top: 0;
        z-index: 100;
        border-radius: 10px;
    }
}
