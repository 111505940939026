.branding__main-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-content: center;
    gap: 50px;
    border-top: 1px solid rgb(255, 255, 255, 0.4);
    padding-top: 30px;
}

a.branding__link {
    opacity: 50%;
}

a.branding__link:hover {
    opacity: 80%;
}

.branding_img__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 15px;
    background: white;
    border-radius: 20px;
}

.branding_img-size {
    height: 60px;
    width: auto;
}

@media (max-width: 700px) {
    .branding__main-wrapper {
        flex-flow: column;
        gap: 30px;
        align-items: center;
    }
}

