.words__main-wrapper {
    display: inline-block;
    text-align: center;
}

@media (max-width: 700px) {
    .words__main-wrapper {
        display: flex;
        flex-flow: column;
    }
}

.word__single {
    background: rgb(255, 255, 255, 0.2);
    border-radius: 10px;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    margin-bottom: 10px;
}
