#tripleResultTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: white;
}

.results-triple-table_border-top {
    border-top: 1px solid rgb(255, 255, 255, 0.8);
}

#tripleResultTable td {
    vertical-align: top;
    padding: 25px 15px;
    border-bottom: 1px solid rgb(255, 255, 255, 0.8);
}

@media (max-width: 1200px) {
    #tripleResultTable td {
        padding: 15px 5px;
    }
}

#tripleResultTable tr:nth-child(odd){
    background-color: rgb(255, 255, 255, 0.15);
}

.results-triple-table_active-substances__list {
    padding: 0;
    margin: 0;
}

.results-triple-table_dressing-name__text {
    font-size: 24px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

@media (max-width: 1200px) {
    .results-triple-table_dressing-name__text {
        font-size: 18px;
    }
}

.results-triple-table_dressing-producer__text {
    font-size: 12px;
    padding: 0;
    margin: 4px 0 0 0;
}

.results-triple-table__section-header {
    border: 1px solid rgb(255, 255, 255, 0.2);
    color: white;
    font-size: 12px;
    border-radius: 4px;
    display: inline-block;
    padding: 2px 6px;
    margin: 0;
}


@media (max-width: 1200px) {
    .results-triple-table__section-header {
        margin-bottom: 5px;
    }
}

.results-triple-table__section-content {
    padding: 0;
    margin: 12px 0 0 0;
}

.results-triple-table__prices-content {
    padding: 0;
    margin: 12px 0 0 0;
    font-size: 12px;
}

.results-triple-table_dressing-action__wrapper {
    padding: 0 30px;
}

@media (max-width: 1200px) {
    .results-triple-table_dressing-action__wrapper {
        padding: 0 5px;
    }
}

.results-triple-table__price-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0 0 0;
    padding: 0;
}

.results-triple-table_B {
    display: flex;
}

@media (max-width: 1200px) {
    .results-triple-table_hide-on-small-screen {
        display: none;
    }

    .results-triple-table__price-text {
        flex-flow: column;
    }
}
