/* reset */
button {
    all: unset;
}

.select-trigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 35px;
    gap: 5px;
    color: white;
    box-shadow: 0 2px 10px black;
}
.select-trigger:hover {
    background-color: grey;
}
.select-trigger:focus {
    box-shadow: 0 0 0 2px black;
}
.select-trigger[data-placeholder] {
    color: var(--violet-switch-button);
}

.select-content {
    overflow: hidden;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    z-index: 200;
}

.select-viewport {
    padding: 5px;
}

.select-item {
    font-size: 12px;
    line-height: 1;
    color: var(--violet-text);
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
}
.select-item[data-disabled] {
    color: grey;
    pointer-events: none;
}
.select-item[data-highlighted] {
    outline: none;
    background-color: #e4ddfd;
    color: var(--violet-text);
}

.default-option {
    font-size: 12px;
    line-height: 1;
    color: var(--violet-text);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
}
.default-option[data-disabled] {
    color: grey;
    pointer-events: none;
}
.default-option[data-highlighted] {
    outline: none;
    background-color: #e4ddfd;
    color: var(--violet-text);
}

.select-label {
    padding: 0 25px;
    font-size: 12px;
    line-height: 25px;
    color: grey;
}

.select-separator {
    height: 1px;
    background-color: lightgrey;
    margin: 5px;
}

.select-item-indicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.select-scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    color: var(--violet-text);
    cursor: default;
}
