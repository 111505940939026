#resultsDressingPriceTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
    color: white;
}

#resultsDressingPriceTable th {
    border-bottom: 1px solid rgb(255, 255, 255, 0.7);
    text-align: left;
    font-size: 12px;
    padding: 10px 5px;
}

#resultsDressingPriceTable td {
    vertical-align: top;
    padding: 10px 5px;
    border-bottom: 1px solid rgb(255, 255, 255, 0.4);
}
