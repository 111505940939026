.tags_main-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 10px;
}

.tags_single-tag {
    background: rgb(255, 255, 255, 0.2);
    border-radius: 10px;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
}


@media (max-width: 700px) {
    .tags_main-wrapper {
        flex-flow: column;
        width: 100%;
    }

    .tags_single-tag {
        text-align: center;
    }
}
