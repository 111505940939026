.regulations__main-wrapper {
    width: 100vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 12px;
    color: white;
    text-align: justify;
}

.regulations_button__wrapper {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.regulations__inner-wrapper {
    width: 80%;
    padding: 30px 25px;
    background: rgb(255, 255, 255, 0.2);
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.regulations_bold-text {
    font-weight: bold;
}

.regulations__medutools-data {
    margin: 0;
    padding: 0;
}

a.regulations_link {
    color: white;
}

a.regulations_link:hover {
    color: lightgray;
}
