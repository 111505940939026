.select-button_main {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 100%;
    height: 35px;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
}
