.login__main-wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 140px;
}

.login__inner-wrapper {
    width: 40%;
    background: rgb(255, 255, 255, 0.1);
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 15px;
    padding: 35px 25px;
}

@media (min-width: 1101px) and (max-width: 1300px) {
    .login__inner-wrapper {
        width: 50%;
    }
}

@media (min-width: 901px) and (max-width: 1100px) {
    .login__inner-wrapper {
        width: 60%;
    }
}

@media (min-width: 801px) and (max-width: 900px) {
    .login__inner-wrapper {
        width: 65%;
    }
}

@media (min-width: 701px) and (max-width: 800px) {
    .login__inner-wrapper {
        width: 80%;
    }
}

@media (max-width: 700px) {
    .login__inner-wrapper {
        width: 90%;
        padding: 20px 10px;
    }
}

.login_label-input__wrapper {
    width: 100%;
    height: 40px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-content: center;
}

@media (max-width: 700px) {
    .login_label-input__wrapper {
        height: auto;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
}

.login_label__wrapper {
    width: 30%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    font-size: 14px;
}

.login_label__inner-wrapper {
    width: 60%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 700px) {
    .login_label__inner-wrapper {
        width: 100%;
        height: 40px;
        justify-content: center;
        align-items: center;
    }
}

.login_input__wrapper {
    width: 60%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_select__wrapper {
    width: 200px;
}

@media (max-width: 700px) {
    .login_input__wrapper {
        width: 100%;
    }
    .login_select__wrapper {
        width: 202px;
    }
}

.login_claim__wrapper {
    width: 100%;
    font-size: 11px;
    color: white;
    text-align: center;
    margin-top: 10px;
}

.login_checkbox__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.login_button__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
