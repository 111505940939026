.single-result__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    margin: 0;
    padding: 0;
}

.single-result__content {
    width: 80%;
    display: flex;
    flex-flow: column;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
}

@media (max-width: 1200px) {
    .single-result__content {
        width: 95%;
    }
}

.single-result_button__wrapper {
    margin-bottom: 30px;
}

.single-result_drug-header__wrapper {
    text-align: center;
    color: white;
    border-bottom: 1px solid rgb(255, 255, 255, 0.3);
    padding-bottom: 20px;
    margin-bottom: 30px;
}

.single-result_drug-header-name__text {
    font-size: 26px;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.single-result_drug-header-company__text {
    font-size: 12px;
    margin: 0;
}
