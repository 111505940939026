:root {
  --green: #00ff0c;
  --violet-switch-button: #bfaffe;
  --violet-button-hover: #b3a0fd;
  --violet-light-background: #c9b2fd;
  --violet-text: #9210fe;
  --violet-dark: #230386;
  --alert-color: #00ff0c;
  --violet-midde: #9980fc;
  --violet-see-more-hover: #dedefc;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(174,44,241);
  background: linear-gradient(90deg, rgba(174,44,241,1) 0%, rgba(119,118,255,1) 70%);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.input-forms__wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin: 200px 0 0;
}

@media (max-width: 750px) {
  .input-forms__wrapper {
    margin-top: 60px;
  }
}

.general_form__wrapper {
  display: flex;
  flex-flow: column;
  gap: 30px;
}

.general_form-header-text {
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 0;
  margin: 0;
}

.general_switch-input__wrapper {
  border: 1px solid white;
  border-radius: 20px;
  padding: 15px 20px;
}

.form-button-right__wrapper {
  display: flex;
  justify-content: flex-end;
}

.form-two-buttons__wrapper {
  display: flex;
  justify-content: space-between;
}

.display-none {
  display: none;
}


/* ========= general_form__wrapper =============== */
@media (max-width: 450px) {
  .general_form__wrapper {
    width: 98vw;
  }
}

@media (min-width: 451px) and (max-width: 610px) {
  .general_form__wrapper {
    width: 90vw;
  }
}

@media (min-width: 611px) and (max-width: 730px) {
  .general_form__wrapper {
    width: 80vw;
  }
}

@media (min-width: 731px) and (max-width: 900px) {
  .general_form__wrapper {
    width: 70vw;
  }
}

@media (min-width: 901px) and (max-width: 1024px) {
  .general_form__wrapper {
    width: 60vw;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .general_form__wrapper {
    width: 50vw;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .general_form__wrapper {
    width: 35vw;
  }
}

@media (min-width: 1401px) {
  .general_form__wrapper {
    width: 30vw;
  }
}

.border-left {
  border-left: 1px solid rgb(255, 255, 255, 0.3);
}

.additional-table-column {
  border-right: 1px solid rgb(255, 255, 255, 0.5);
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);
  color: white;
  font-size: 12px;
}

.border-top {
  border-top: 1px solid rgb(255, 255, 255, 0.8);
}

.font-size-12 {
  font-size: 12px;
}

.results-all-table_section-headers__text {
  font-size: 12px;
  text-align: center;
}

@media (min-width: 700px) {
  .results-all-table_section-headers__text {
    display: none;
  }
}
