.checkbox_main-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

button {
    all: unset;
}

.checkbox-root {
    width: 18px;
    height: 18px;
    border: 1px solid white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkbox-root:hover {
    cursor: pointer;
}

.checkbox-root:disabled {
    cursor: auto;
    opacity: 20%;
}

.checkbox-indicator {
    color: var(--violet-text);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
}

.checkbox-label {
    color: white;
    font-size: 14px;
    line-height: 1;
}

.checkbox-regulations-label {
    color: white;
    font-size: 11px;
    line-height: 1;
}

.checkbox_regulations-text {
    font-size: 11px;
    color: white;
    text-decoration: underline;
}

.checkbox_regulations-text:hover {
    text-decoration: underline;
}
