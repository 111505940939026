.default-button {
    background: var(--violet-switch-button);
    width: 100px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    color: var(--violet-text);
    font-size: 12px;
    opacity: 80%;
}

.default-button:hover {
    background: white;
    color: var(--violet-text);
    cursor: pointer;
}

.compare-dressings-button {
    width: 30%;
    padding: 10px 10px;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    background: var(--violet-see-more-hover);
    color: var(--violet-midde);
}

@media (min-width: 801px) and (max-width: 1100px) {
    .compare-dressings-button {
        width: 50%;
    }
}

@media (max-width: 800px) {
    .compare-dressings-button {
        width: 60%;
    }
}

.compare-dressings-button:hover {
    background: white;
    cursor: pointer;
}

.compare-dressings-button:disabled {
    background: rgb(255, 255, 255, 0.1);
    color: rgb(255, 255, 255, 0.4);
    border: none;
}

.compare-dressings-button:disabled:hover {
    cursor: default;
}


@media (max-width: 950px) {
    .compare-dressings-button:disabled {
        color: var(--violet-midde);
        background: rgb(255, 255, 255, 0.6);
    }
    .compare-dressings-button {
        width: 100%;
        padding: 10px 10px;
        text-align: center;
        font-size: 12px;
        border-radius: 10px;
        background: white;
        color: var(--violet-midde);
    }
}

.reset-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    background: var(--violet-switch-button);
    width: 22px;
    height: 20px;
    border-radius: 50%;
    color: white;
    font-size: 8px;
    opacity: 60%;
}

.reset-button:hover {
    background: white;
    color: var(--violet-text);
    cursor: pointer;
}

.login-button {
    background: var(--violet-switch-button);
    width: 140px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    color: var(--violet-text);
    font-size: 12px;
    opacity: 80%;
}

.login-button:hover {
    background: white;
    color: var(--violet-text);
    cursor: pointer;
}

.login-button:disabled {
    background: none;
    border: 1px solid rgb(255, 255, 255, 0.2);
    color: rgb(255, 255, 255, 0.2);
    cursor: default;
}
