.input-main__wrapper {
    width: 40%;
    height: 22px;
    background: none;
    border-radius: 8px;
    border: 1px solid white;
    color: white;
    padding: 5px 20px;
}

@media (min-width: 1101px) and (max-width: 1500px) {
    .input-main__wrapper {
        width: 50%;
    }
}

@media (min-width: 701px) and (max-width: 1100px) {
    .input-main__wrapper {
        width: 60%;
    }
}

@media (max-width: 700px) {
    .input-main__wrapper {
        width: 70%;
    }
}

.input-main__wrapper:focus {
    outline: none;
}

.input-main__wrapper::placeholder {
    font-size: 14px;
    color: white;
}
