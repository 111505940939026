.results__wrapper {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    margin: 0;
    padding: 0;
    font-size: 14px;
    width: 100vw;
}

.results__content {
    width: 80%;
    display: flex;
    flex-flow: column;
    padding-top: 30px;
}

@media (min-width: 1251px) and (max-width: 1400px) {
    .results__content {
        width: 85%;
        padding-top: 15px;
    }
}

@media (min-width: 901px) and (max-width: 1250px) {
    .results__content {
        width: 98%;
        padding-top: 15px;
    }
}

@media (max-width: 950px) {
    .results__content {
        width: 98%;
        padding-top: 15px;
    }
}

.results_button__wrapper {
    margin-bottom: 30px;
}

.results__no-results {
    text-align: center;
    color: white;
    padding-top: 60px;
    padding-bottom: 30px;
}

.results_filters__outer-wrapper {
    border: 1px solid rgb(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 10px;
}

.results_filters-header__wrapper {
    margin-bottom: 40px;
}

@media (max-width: 700px) {
    .results_filters-header__wrapper {
        margin-bottom: 60px;
    }
}

.results_filters-content__wrapper {
    display: flex;
    flex-flow: row;
    gap: 20px;
}

@media (max-width: 900px) {
    .results_filters-content__wrapper {
        flex-flow: column;
        align-items: center;
    }
}

.results_filters-content-right-left__wrapper {
    width: 50%;
    display: flex;
    flex-flow: column;
    gap: 20px;
}

@media (max-width: 900px) {
    .results_filters-content-right-left__wrapper {
        width: 100%;
        gap: 15px;
    }
}

.results_filters-single-filter__wrapper {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}

@media (min-width: 0px) and (max-width: 620px) {
    .results_filters-single-filter__wrapper {
        flex-flow: column;
        gap: 5px;
    }
}

@media (min-width: 621px) and (max-width: 700px) {
    .results_filters-single-filter__wrapper {
        flex-flow: row;
        gap: 5px;
    }
}

@media (min-width: 701px) and (max-width: 1020px) {
    .results_filters-single-filter__wrapper {
        flex-flow: column;
        gap: 5px;
    }
}

.results_filters-single-filter-label__wrapper {
    width: 35%;
}

@media (min-width: 0px) and (max-width: 400px) {
    .results_filters-single-filter-label__wrapper {
        width: 100%;
        text-align: center;
        margin-left: -10px;

    }
}

@media (min-width: 401px) and (max-width: 620px) {
    .results_filters-single-filter-label__wrapper {
        width: 100%;
        text-align: center;
        padding-left: 20px;
    }
}

@media (min-width: 621px) and (max-width: 700px) {
    .results_filters-single-filter-label__wrapper {
        width: 100%;
        text-align: left;
    }
}

@media (min-width: 701px) and (max-width: 800px) {
    .results_filters-single-filter-label__wrapper {
        width: 100%;
        text-align: center;
        margin-left: -20px;
    }
}

@media (min-width: 801px) and (max-width: 900px) {
    .results_filters-single-filter-label__wrapper {
        width: 100%;
        text-align: center;
        padding-left: 20px;
    }
}

@media (min-width: 901px) and (max-width: 950px) {
    .results_filters-single-filter-label__wrapper {
        width: 100%;
        text-align: left;
        padding-left: 20px;
    }
}


@media (min-width: 951px) and (max-width: 1020px) {
    .results_filters-single-filter-label__wrapper {
        width: 100%;
        text-align: left;
        padding-left: 60px;
    }
}

.results_label {
    font-size: 12px;
    color: white;
}

.results_filters-single-filter-content__wrapper {
    width: 65%;
    display: flex;
    justify-content: center;
}

@media (min-width: 0px) and (max-width: 700px) {
    .results_filters-single-filter-content__wrapper {
        width: 100%;
    }
}

.results_filters-single-filter-content__inner-wrapper {
    width: 100%;
    display: flex;
}

@media (max-width: 1130px) {
    .results_filters-single-filter-content__inner-wrapper {
        width: 100%;
    }
}

@media (max-width: 1130px) {
    .results_filters-single-filter-content__wrapper {
        width: 100%;
    }
}

.results_filters-select__wrapper {
    width: 301px;
    max-width: 301px;
    display: flex;
    gap: 10px;
}

@media (min-width: 0px) and (max-width: 250px) {
    .results_filters-select__wrapper {
        width: 161px;
        max-width: 161px;
        gap: 5px;
    }
}

@media (min-width: 251px) and (max-width: 305px) {
    .results_filters-select__wrapper {
        width: 201px;
        max-width: 201px;
        gap: 5px;
    }
}

@media (min-width: 306px) and (max-width: 360px) {
    .results_filters-select__wrapper {
        width: 241px;
        max-width: 241px;
        gap: 5px;
    }
}

@media (min-width: 361px) and (max-width: 500px) {
    .results_filters-select__wrapper {
        width: 281px;
        max-width: 281px;
        gap: 5px;
    }
}

.results_filters-single-filter-content__left-wrapper {
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 701px) and (max-width: 1020px) {
    .results_filters-single-filter-content__left-wrapper {
        width: 70%;
    }
}

@media (min-width: 621px) and (max-width: 700px) {
    .results_filters-single-filter-content__left-wrapper {
        width: 92%;
    }
}

@media (min-width: 581px) and (max-width: 620px) {
    .results_filters-single-filter-content__left-wrapper {
        width: 78%;
    }
}

@media (min-width: 531px) and (max-width: 580px) {
    .results_filters-single-filter-content__left-wrapper {
        width: 80%;
    }
}

@media (min-width: 461px) and (max-width: 530px) {
    .results_filters-single-filter-content__left-wrapper {
        width: 82%;
    }
}

@media (min-width: 361px) and (max-width: 460px) {
    .results_filters-single-filter-content__left-wrapper {
        width: 86%;
    }
}

@media (min-width: 0px) and (max-width: 360px) {
    .results_filters-single-filter-content__left-wrapper {
        width: 90%;
    }
}

.results_filters-single-filter-content__right-wrapper {
    width: 10%;
    display: flex;
    align-items: center;
}

.results_alert__wrapper {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: -35px;
}

@media (max-width: 700px) {
    .results_filters__outer-wrapper {
        margin-top: 10px;
    }

    .results_filters-header__wrapper {
        margin-top: -25px;
    }

    .results_alert__wrapper {
        height: 25px;
        font-size: 10px;
        margin-top: -75px;
    }
}

.results_alert-text__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--alert-color);
    color: var(--violet-text);
    padding: 5px 50px;
    border-radius: 10px;
    font-size: 12px;
}

.results_reset-button__wrapper {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-content: center;
    z-index: 10;
}

@media (max-width: 700px) {
    .results_reset-button__wrapper {
        padding-top: 15px;
    }
}

.results_select-day-input__wrapper {
    width: 48%;
}

.results_number-of-dressings__wrapper {
    margin-bottom: 40px;
}

.results_search-bar__wrapper {
    width: 100%;
    display: flex;
    flex-flow: row;
    padding: 0;
    margin: 0 0 4px;
}

@media (max-width: 700px) {
    .results_search-bar__wrapper {
        flex-flow: column;
        gap: 10px;
    }
}

.results_search-bar__left-wrapper {
    width: 50%;
    height: 35px;
    padding: 0;
    margin: 0;
}

.results_search-bar__inner-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

@media (max-width: 950px) {
    .results_search-bar__inner-wrapper {
        width: 75%;
        justify-content: center;
    }
}

@media (max-width: 950px) {
    .results_search-bar__left-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.results_search-bar__right-wrapper {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 951px) and (max-width: 1200px) {
    .results_search-bar__left-wrapper {
        width: 50%;
    }
    .results_search-bar__right-wrapper {
        width: 50%;
    }
}

@media (max-width: 950px) {
    .results_search-bar__right-wrapper {
        display: none;
    }
}

.results_broaden-search-criteria {
    font-size: 12px;
    text-align: center;
}

.results_content__wrapper {
    background-color:rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    padding: 30px 20px;
    margin-bottom: 30px;
    color: white;
}

.results_branding__wrapper {
    width: 100%;
    margin-bottom: 20px;
}

.results_copyright__wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    opacity: 50%;
}
