.triple-result__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    margin: 0;
    padding: 0;
}

.triple-result__content {
    width: 80%;
    display: flex;
    flex-flow: column;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
}

@media (max-width: 1500px) {
    .triple-result__content {
        width: 100%;
    }
}

.triple-result_button__wrapper {
    margin-bottom: 30px;
}

.triple-result_table__wrapper {
    padding-bottom: 40px;
}
