.results-single-table__main-wrapper {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 14px;
}

.results-single-table_content__wrapper {
    display: flex;
    flex-flow: wrap;
}

@media (max-width: 700px) {
    .results-single-table_content__wrapper {
        flex-flow: column;
    }
}

.results-single-table_prices__wrapper {
    display: flex;
    flex-flow: column;
}

.results-single-table_prices-header__wrapper {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid rgb(255, 255, 255, 0.4);
}

.results-single-table_prices-content__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(255, 255, 255, 0.4);
    margin-bottom: 20px;
}

.results-single-table_prices-content__inner-wrapper {
    width: 70%;
    background: rgb(255, 255, 255, 0.1);
    padding: 10px 20px;
    border-radius: 20px;
}

@media (max-width: 700px) {
    .results-single-table_prices-content__inner-wrapper {
        width: 100%;
    }
}

.results-single-table_element-wrapper {
    width: 33.33%;
    display: flex;
    flex-flow: column;
}

@media (max-width: 700px) {
    .results-single-table_element-wrapper {
        width: 100%;
    }
}

.results-single-table_element-header__wrapper {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-left: 15px;
    height: 50px;
    background: rgb(255, 255, 255, 0.2);
}

@media (max-width: 700px) {
    .results-single-table_element-header__text {
        padding: 5px 20px;
        border: 1px solid white;
        border-radius: 10px;
    }

    .results-single-table_element-header__wrapper {
        justify-content: center;
        padding-left: 0;
        height: 35px;
        background: none;
    }
}

.results-single-table_element-content__wrapper {
    padding: 20px 15px;
}

@media (max-width: 700px) {
    .results_center-text-on-small-screen {
        text-align: center;
    }
}

.results-single-table_structure-tags__wrapper {
    display: flex;
    flex-flow: column;
    gap: 20px;
}
